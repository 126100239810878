:root {
  --colors-backgroundInner: #ffffff;
  --colors-backgroundOuter: #fcfcfc;
  --colors-backgroundHeader: rgba(252, 252, 252, 0.7);
  --colors-text: #202020;
  --colors-mediumDark: #515151;
  --colors-medium: #5e5e5e;
  --colors-mediumLight: #757575;
  --colors-light: #d2d2d2;
  --colors-kindaLight: #e3e3e3;
  --colors-superLight: #f4f4f4;
  --colors-superDuperLight: #fbfbfb;
  --colors-link: #0e6dc2;
  --colors-linkUnderline: rgba(14, 109, 194, 0.4);
  --colors-success: #44a248;
  --colors-error: #ff1b1b;
  --colors-warning: #f78200;
  --colors-codeText: #313131;
  --colors-codeBackground: #fdfdfd;
  --colors-codeComment: #656e77;
  --colors-codeKeyword: #029cb9;
  --colors-codeAttribute: #70a800;
  --colors-codeNamespace: #f92672;
  --colors-codeLiteral: #ae81ff;
  --colors-codePunctuation: #111111;
  --colors-codeVariable: #d88200;
  --colors-codeAddition: #44a248;
  --colors-codeDeletion: #ff1b1b;
  --sizes-maxLayoutWidth: 865px;
  --radii-corner: 0.6rem;
  --transitions-fade: 0.25s ease;
  --transitions-linkHover: 0.2s ease-in-out;
}

[data-theme="dark"] {
  --colors-backgroundInner: #1e1e1e;
  --colors-backgroundOuter: #252525;
  --colors-backgroundHeader: rgba(37, 37, 37, 0.85);
  --colors-text: #f1f1f1;
  --colors-mediumDark: #d7d7d7;
  --colors-medium: #b1b1b1;
  --colors-mediumLight: #959595;
  --colors-light: #646464;
  --colors-kindaLight: #535353;
  --colors-superLight: #272727;
  --colors-superDuperLight: #1f1f1f;
  --colors-link: #88c7ff;
  --colors-linkUnderline: rgba(136, 199, 255, 0.4);
  --colors-success: #78df55;
  --colors-error: #ff5151;
  --colors-warning: #f2b702;
  --colors-codeText: #e4e4e4;
  --colors-codeBackground: #212121;
  --colors-codeComment: #929292;
  --colors-codeKeyword: #3b9dd2;
  --colors-codeAttribute: #78df55;
  --colors-codeNamespace: #f95757;
  --colors-codeLiteral: #d588fb;
  --colors-codePunctuation: #cccccc;
  --colors-codeVariable: #fd992a;
  --colors-codeAddition: #78df55;
  --colors-codeDeletion: #ff5151;
}
